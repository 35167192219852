import { HttpParams } from '@angular/common/http'

export const setHttpParams = (data: { [key: string]: any }): HttpParams => {
  let params = new HttpParams()

  for (const key of Object.keys(data)) {
    if (typeof data[key] === 'object') {
      params = params.append(key, JSON.stringify(data[key]))
    } else {
      params = params.append(key, data[key])
    }
  }

  return params
}
